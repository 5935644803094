import React, { useState } from 'react';
import Nav from './Nav';
import SubNav from './SubNav';
import TopNav from './TopNav';
import { Link } from 'gatsby';
import logo from '../assets/img/logo.png';

export default function SideBar({ sections = [] }) {
  const [headerOpen, toggleHeader] = useState(false);
  return (
    <>
      <header id="header">
        <div className="inner">
          <Link to="/" className="logo">
            <span className="title">
              <img style={{ width: 130 }} src={logo} alt="" />
            </span>
          </Link>
          <Link to="/offer" className="button_client">
            <span className="title">What we offer</span>
          </Link>

          <TopNav onMenuClick={() => toggleHeader(!headerOpen)} />
        </div>
      </header>
      <div className={`${headerOpen ? 'is-menu-visible' : ' '}`}>
        <Nav onClose={() => toggleHeader(!headerOpen)} />
      </div>
    </>
  );
}
